<template>
  <div id="contact" class="reader">
    <h2>Contact Me</h2>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
.reader {
 width: 100%;
 padding: 30px 30px;
 border-radius: 0 0 2rem 0;
}
</style>
